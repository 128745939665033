@use '@angular/material' as mat;

@include mat.core();

$md-followmeprimary: (
  50 : #e1e3ed,
  100 : #b4b8d1,
  200 : #8289b3,
  300 : #4f5a95,
  400 : #2a367e,
  500 : #041367,
  600 : #03115f,
  700 : #030e54,
  800 : #020b4a,
  900 : #010639,
  A100 : #6f73ff,
  A200 : #3c42ff,
  A400 : #0910ff,
  A700 : #0007ef,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-followmesecondary: (
  50 : #efeaff,
  100 : #d8caff,
  200 : #bea6ff,
  300 : #a382ff,
  400 : #9068ff,
  500 : #7c4dff,
  600 : #7446ff,
  700 : #693dff,
  800 : #5f34ff,
  900 : #4c25ff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #dad3ff,
  A700 : #c4b9ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-followmewarn: (
  50 : #fee6eb,
  100 : #fcc1ce,
  200 : #fb98ad,
  300 : #f96e8c,
  400 : #f74f74,
  500 : #f6305b,
  600 : #f52b53,
  700 : #f32449,
  800 : #f21e40,
  900 : #ef132f,
  A100 : #ffffff,
  A200 : #ffebed,
  A400 : #ffb8bf,
  A700 : #ff9ea8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my-primary: mat.define-palette($md-followmeprimary);
$my-accent: mat.define-palette($md-followmesecondary);

$my-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
      ),
      density: -2,
    )
);



@include mat.all-component-themes($my-theme);

.custom-element {
  background-color: mat.get-color-from-palette($my-primary, 700);
  color: mat.get-color-from-palette($my-primary, 700-contrast);
}
