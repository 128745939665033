// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #7C4DFF;
  --ion-color-primary-rgb: 124,77,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6d44e0;
  --ion-color-primary-tint: #895fff;

  --ion-background-color: #fcfcff;

  --ion-color-secondary: #251552;
  --ion-color-secondary-rgb: 4,19,103;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #04115b;
  --ion-color-secondary-tint: #1d2b76;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #30D158;
  --ion-color-success-rgb: 48,209,88;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #2ab84d;
  --ion-color-success-tint: #45d669;

  --ion-color-warning: #FF7719;
  --ion-color-warning-rgb: 255,119,25;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e06916;
  --ion-color-warning-tint: #ff8530;

  --ion-color-danger: #F6305B;
  --ion-color-danger-rgb: 246,48,91;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d82a50;
  --ion-color-danger-tint: #f7456b;

  --ion-color-medium: #798291;
  --ion-color-medium-rgb: 121,130,145;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #6a7280;
  --ion-color-medium-tint: #868f9c;

  --ion-color-light: #CACBD2;
  --ion-color-light-rgb: 202,203,210;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #b2b3b9;
  --ion-color-light-tint: #cfd0d7;

  // BORDER COLOR FOR LIST IN BACKOFFICE
  --ion-color-border-list: #ECEFF1;
  --ion-color-border-list-rgb: 236,239,241;
  --ion-color-border-list-contrast: #000000;
  --ion-color-border-list-contrast-rgb: 0,0,0;
  --ion-color-border-list-shade: #d0d2d4;
  --ion-color-border-list-tint: #eef1f2;

  // TEXT COLOR IN BACKOFFICE BUT NOT ALL BECAUSE WHO CARE ABOUT LOGIC IN GRAPHIC CHARTS
  --ion-color-secondary-text: #23307A;
  --ion-color-secondary-text-rgb: 53,52,84;
  --ion-color-secondary-text-contrast: #ffffff;
  --ion-color-secondary-text-contrast-rgb: 255,255,255;
  --ion-color-secondary-text-shade: #2f2e4a;
  --ion-color-secondary-text-tint: #494865;

  --ion-text-color: var(--ion-color-secondary);
}

.ion-color-border-list {
  --ion-color-base: var(--ion-color-border-list);
  --ion-color-base-rgb: var(--ion-color-border-list-rgb);
  --ion-color-contrast: var(--ion-color-border-list-contrast);
  --ion-color-contrast-rgb: var(--ion-color-border-list-contrast-rgb);
  --ion-color-shade: var(--ion-color-border-list-shade);
  --ion-color-tint: var(--ion-color-border-list-tint);
}

.ion-color-secondary-text {
  --ion-color-base: var(--ion-color-secondary-text);
  --ion-color-base-rgb: var(--ion-color-secondary-text-rgb);
  --ion-color-contrast: var(--ion-color-secondary-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-text-shade);
  --ion-color-tint: var(--ion-color-secondary-text-tint);
}

