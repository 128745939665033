.pointer {
  cursor: pointer
}

.more-padding {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;

  padding: 20px
}

//STOP THE CONTENT PUSH WHEN KEYBOARD OPEN
ion-content {
  --keyboard-offset: 0 !important;
  --offset-top: 0px !important;
  --offset-bottom: 0px !important;
}


  //FIRST CONNEXION CLASS

fm-header-first-connexion {
  width: 100%;
}


// MODAL

ion-modal {
  --height: fit-content;
  --border-radius: 8px;
  --width: 99%;

  &.open-cgu-modal {
    --height: 95%;
    --width: 95%;
  }
}

.grid-modal {
  width: 100%;
  padding: 16px;

  ion-row {
    width: 100%;
  }

  .header-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    ion-icon {
      font-size: 24px;
    }
    .header-modal-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 32px;

      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 50px;
        background-color: var(--ion-color-danger);
        margin-right: 8px;
        ion-icon {
          color: white;
        }
      }

      ion-text {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .footer-modal {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    ion-button {
      width: 45%;
    }
  }
}
